<template>
  <v-container class="about ps-6" >
    <h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>Benedikt Binzer<br />
      Hans-Riggenbach-Straße 8<br />
      65795 Hattersheim</p>

    <h2>Kontakt</h2>
    <p>Telefon: +49 1573 9456571<br />
      E-Mail: benedikt@wedding-flow.de</p>

    <h2>Redaktionell verantwortlich</h2>
    <p>Benedikt Binzer<br />
      Hans-Riggenbach-Straße 8<br/>
      65795 Hattersheim</p>

    <h2>EU-Streitschlichtung</h2>
    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
  </v-container>
</template>
<script>



export default {
  name: 'Imprint',
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
<style>
h2{
  padding-top: 15px;
  padding-bottom: 10px
}

</style>
